import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  getCreators() {
    return this.http.get("https://api.npoint.io/36e084e26d28271c5f4c");
  }

}
