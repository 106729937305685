<div class=" sm:block sticky-header" style="background-color: #2815FF;">
  <a href="tel:+212623841732" style="text-decoration: none; color: white;">
      <i class="fa fa-phone" style="font-size: 16px; color: white;"></i>
      <span style="font-weight: bold; font-size: 14px; color: white;">+212623 841 732</span>
  </a>

  |

  <a href="mailto:support@ugc4arabs.com" style="text-decoration: none; color: white;">
      <i class="fa fa-envelope" style="font-size: 16px; color: white;"></i>
      <span style="font-weight: bold; font-size: 14px; color: white;">support@ugc4arabs.com</span>
  </a>
</div>
<!--<div style="text-align: center;padding:10px;"> <i class="fa fa-phone" style="font-size:16px;color:black"><a href="tel:+212636713296" ></i> <span  style=" font-weight:bold;font-size:14px;color:black">+212 636 713 296 </span></a>  | <a  href="mailto:info@voixoff.ma"> <i class="fa fa-envelope" style="font-size:16px;color:black"></i> <span  style=" font-weight:bold;font-size:14px;color:black">info@voixoff.ma</span></div>


</a>
-->


<div class="fixed z-[60] inset-0 flex items-end justify-center px-4 py-6
pointer-events-none sm:p-5 sm:pt-4 sm:items-start sm:justify-center" x-data="wireui_notifications"
  x-on:wireui:notification.window="addNotification($event.detail)"
  x-on:wireui:confirm-notification.window="addConfirmNotification($event.detail)" wire:ignore="">
  <div class="max-w-sm w-full space-y-2 pointer-events-auto flex flex-col-reverse">
      <template x-for="notification in notifications" :key="`notification-${notification.id}`">
          <div class="max-w-sm w-full bg-white shadow-lg rounded-lg ring-1 ring-black
          ring-opacity-5 relative overflow-hidden pointer-events-auto
          dark:bg-secondary-800 dark:border dark:border-secondary-700"
              :class="{ 'flex': notification.rightButtons }" :id="`notification.${notification.id}`"
              x-transition:enter="transform ease-out duration-300 transition"
              x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0"
              x-on:mouseenter="pauseNotification(notification)"
              x-on:mouseleave="resumeNotification(notification)">
              <div class="bg-secondary-300 dark:bg-secondary-600 rounded-full transition-all duration-150 ease-linear absolute top-0 left-0"
                  style="height: 2px; width: 100%;" :id="`timeout.bar.${notification.id}`"
                  x-show="Boolean(notification.timer) &amp;&amp; notification.progressbar !== false">
              </div>
              <div :class="{
          'pl-4': Boolean(notification.dense),
          'p-4': !Boolean(notification.rightButtons),
          'w-0 flex-1 flex items-center p-4': Boolean(notification.rightButtons),
      }">
                  <div :class="{
          'flex items-start': !Boolean(notification.rightButtons),
          'w-full flex': Boolean(notification.rightButtons),
      }">
                      <!-- notification icon|img -->
                      <template x-if="notification.icon || notification.img">
                          <div class="shrink-0" :class="{
                      'w-6': Boolean(notification.icon),
                      'pt-0.5': Boolean(notification.img),
                  }">
                              <template x-if="notification.icon">
                                  <div class="notification-icon"></div>
                              </template>

                              <template x-if="notification.img">
                                  <img class="h-10 w-10 rounded-full" :src="notification.img">
                              </template>
                          </div>
                      </template>

                      <div class="w-0 flex-1 pt-0.5" :class="{
                  'ml-3': Boolean(notification.icon || notification.img)
              }">
                          <p class="text-sm font-medium text-secondary-900 dark:text-secondary-400"
                              x-show="notification.title" x-html="notification.title">
                          </p>
                          <p class="mt-1 text-sm text-secondary-500" x-show="notification.description"
                              x-html="notification.description">
                          </p>

                          <!-- actions buttons -->
                          <template
                              x-if="!notification.dense &amp;&amp; !notification.rightButtons &amp;&amp; (notification.accept || notification.reject)">
                              <div class="mt-3 flex gap-x-3">
                                  <button class="rounded-md text-sm font-medium focus:outline-none" :class="{
                              'bg-white dark:bg-transparent text-primary-600 hover:text-primary-500': !Boolean($wireui.dataGet(notification, 'accept.style')),
                              [$wireui.dataGet(notification, 'accept.style')]: Boolean($wireui.dataGet(notification, 'accept.style')),
                              'px-3 py-2 border shadow-sm': Boolean($wireui.dataGet(notification, 'accept.solid')),
                          }" x-on:click="accept(notification)"
                                      x-show="$wireui.dataGet(notification, 'accept.label')"
                                      x-text="$wireui.dataGet(notification, 'accept.label', '')">
                                  </button>

                                  <button class="rounded-md text-sm font-medium focus:outline-none" :class="{
                              'bg-white dark:bg-transparent text-secondary-700 dark:text-secondary-600 hover:text-secondary-500': !Boolean($wireui.dataGet(notification, 'reject.style')),
                              [$wireui.dataGet(notification, 'reject.style')]: Boolean($wireui.dataGet(notification, 'reject.style')),
                              'px-3 py-2 border border-secondary-300 shadow-sm': Boolean($wireui.dataGet(notification, 'accept.solid')),
                          }" x-on:click="reject(notification)"
                                      x-show="$wireui.dataGet(notification, 'reject.label')"
                                      x-text="$wireui.dataGet(notification, 'reject.label', '')">
                                  </button>
                              </div>
                          </template>
                      </div>

                      <div class="ml-4 shrink-0 flex">
                          <!-- accept button -->
                          <button class="mr-4 shrink-0 rounded-md text-sm font-medium focus:outline-none" :class="{
                      'text-primary-600 hover:text-primary-500': !Boolean($wireui.dataGet(notification, 'accept.style')),
                      [$wireui.dataGet(notification, 'accept.style')]: Boolean($wireui.dataGet(notification, 'accept.style'))
                  }" x-on:click="accept(notification)" x-show="notification.dense &amp;&amp; notification.accept"
                              x-text="$wireui.dataGet(notification, 'accept.label', '')">
                          </button>

                          <!-- close button -->
                          <button
                              class="rounded-md inline-flex text-secondary-400 hover:text-secondary-500 focus:outline-none"
                              x-show="notification.closeButton" x-on:click="closeNotification(notification)">
                              <span class="sr-only">Close</span>
                              <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
                                  viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"></path>
                              </svg>
                          </button>
                      </div>
                  </div>
              </div>

              <!-- right actions buttons -->
              <template x-if="notification.rightButtons">
                  <div class="flex flex-col border-l border-secondary-200 dark:border-secondary-700">
                      <template x-if="notification.accept">
                          <div class="h-0 flex-1 flex" :class="{
                  'border-b border-secondary-200 dark:border-secondary-700': notification.reject
              }">
                              <button class="w-full rounded-none rounded-tr-lg px-4 py-3 flex items-center
                                 justify-center text-sm font-medium focus:outline-none" :class="{
                          'text-primary-600 hover:text-primary-500 hover:bg-secondary-50 dark:hover:bg-secondary-700': !Boolean(notification.accept.style),
                          [notification.accept.style]: Boolean(notification.accept.style),
                          'rounded-br-lg': !Boolean(notification.reject),
                      }" x-on:click="accept(notification)" x-text="notification.accept.label">
                              </button>
                          </div>
                      </template>

                      <template x-if="notification.reject">
                          <div class="h-0 flex-1 flex">
                              <button class="w-full rounded-none rounded-br-lg px-4 py-3 flex items-center
                                  justify-center text-sm font-medium focus:outline-none" :class="{
                          'text-secondary-700 hover:text-secondary-500 dark:text-secondary-600 hover:bg-secondary-50 dark:hover:bg-secondary-700': !Boolean(notification.reject.style),
                          [notification.reject.style]: Boolean(notification.reject.style),
                          'rounded-tr-lg': !Boolean(notification.accept),
                      }" x-on:click="reject(notification)" x-text="notification.reject.label">
                              </button>
                          </div>
                      </template>
                  </div>
              </template>
          </div>
      </template>
  </div>
</div>


<div class="fixed inset-0 flex items-end overflow-y-auto sm:pt-16 justify-center sm:items-center z-50"
  x-data="wireui_dialog({ id: 'dialog' })" x-show="show" x-on:wireui:dialog.window="showDialog($event.detail)"
  x-on:wireui:confirm-dialog.window="confirmDialog($event.detail)" x-on:keydown.escape.window="handleEscape"
  style="display: none">
  <div class="fixed inset-0 bg-secondary-400 bg-opacity-60 transform transition-opacity
dialog-backdrop  backdrop-blur-md  dark:bg-secondary-700 dark:bg-opacity-60" x-show="show" x-on:click="dismiss"
      x-transition:enter="ease-out duration-300" x-transition:enter-start="opacity-0"
      x-transition:enter-end="opacity-100" x-transition:leave="ease-in duration-200"
      x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" style="display: none;">
  </div>

  <div class="w-full transition-all p-4 sm:max-w-lg" x-show="show" x-transition:enter="ease-out duration-300"
      x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100" x-transition:leave="ease-in duration-200"
      x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
      x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" x-on:mouseenter="pauseTimeout"
      x-on:mouseleave="resumeTimeout" style="display: none;">
      <div class="relative shadow-md bg-white dark:bg-secondary-800 rounded-xl space-y-4 p-4" :class="{
  'sm:p-5 sm:pt-7': style === 'center',
  'sm:p-0 sm:pt-1': style === 'inline',
}">
          <div class="bg-secondary-300 dark:bg-secondary-600 rounded-full transition-all duration-150 ease-linear absolute top-0 left-0"
              style="height: 2px; width: 100%; display: none;" x-ref="progressbar"
              x-show="dialog &amp;&amp; dialog.progressbar &amp;&amp; dialog.timeout">
          </div>

          <div x-show="dialog &amp;&amp; dialog.closeButton" class="absolute right-2 -top-2"
              style="display: none;">
              <button
                  class="dialog-button-close focus:outline-none p-1 focus:ring-2 focus:ring-secondary-200 rounded-full text-secondary-300"
                  x-on:click="close" type="button">
                  <span class="sr-only">close</span>
                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
              </button>
          </div>

          <div class="space-y-4"
              :class="{ 'sm:space-x-4 sm:flex sm:items-center sm:space-y-0 sm:px-5 sm:py-2': style === 'inline' }">
              <div class="mx-auto flex items-center self-start justify-center shrink-0"
                  :class="{ 'sm:items-start sm:mx-0': style === 'inline' }" x-show="dialog &amp;&amp; dialog.icon"
                  style="display: none;">
                  <div x-ref="iconContainer"></div>
              </div>

              <div class="mt-4 w-full" :class="{ 'sm:mt-5': style === 'center' }">
                  <h3 class="text-lg leading-6 font-medium text-secondary-900 dark:text-secondary-400 text-center"
                      :class="{ 'sm:text-left': style === 'inline' }" x-ref="title">

                  </h3>

                  <p class="mt-2 text-sm text-secondary-500 text-center"
                      :class="{ 'sm:text-left': style === 'inline' }" x-ref="description">

                  </p>


              </div>
          </div>

          <div class="grid grid-cols-1 gap-y-2 sm:gap-x-3 rounded-b-xl" :class="{
      'sm:grid-cols-2 sm:gap-y-0': style === 'center',
      'sm:p-4 sm:bg-secondary-100 sm:dark:bg-secondary-800 sm:grid-cols-none sm:flex sm:justify-end': style === 'inline',
  }" x-show="dialog &amp;&amp; (dialog.accept || dialog.reject)" style="display: none;">
              <div x-show="dialog &amp;&amp; dialog.accept" class="sm:order-last" x-ref="accept"
                  style="display: none;"></div>
              <div x-show="dialog &amp;&amp; dialog.reject" x-ref="reject" style="display: none;"></div>
          </div>

          <div class="flex justify-center"
              x-show="dialog &amp;&amp; dialog.close &amp;&amp; !dialog.accept &amp;&amp; !dialog.accept"
              x-ref="close" style="display: none;">
          </div>
      </div>
  </div>
</div>

<div x-data="window.WgRqLnTxHBZBRzq" x-show="open" aria-live="assertive"
  class="fixed inset-0 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[999]"
  id="gig-added-to-cart" x-transition:enter="transform ease-out duration-300 transition"
  x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
  x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0"
  x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100"
  x-transition:leave-end="opacity-0" style="display: none;">
  <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <div
          class="max-w-sm w-full bg-white dark:bg-zinc-800 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
          <div class="p-4">
              <div class="flex items-start">
                  <div class="flex-shrink-0 pt-0.5">
                      <button type="button"
                          class="text-gray-600 bg-gray-100 dark:bg-zinc-700 dark:hover:bg-zinc-600 hover:bg-gray-200 focus:outline-none focus:ring-0 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                              fill="currentColor">
                              <path fill-rule="evenodd"
                                  d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                                  clip-rule="evenodd"></path>
                          </svg>
                      </button>
                  </div>
                  <div class="ltr:ml-3 rtl:mr-3 w-0 flex-1">
                      <p class="text-sm font-medium text-gray-900 dark:text-white">Audio sample added to cart</p>
                      <p class="mt-1 text-xs text-gray-500 dark:text-gray-400" x-text="title"></p>
                      <div class="mt-4 flex">


                          <a href="https://ugc4arabs.com/cart"
                              class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600">View
                              cart</a>


                          <button x-on:click="close" type="button"
                              class="ltr:ml-3 rtl:mr-3 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600">Trouver
                              d'autres voix</button>

                      </div>
                  </div>
                  <div class="ltr:ml-4 rtl:mr-4 flex-shrink-0 flex">
                      <button x-on:click="close()"
                          class="bg-white dark:bg-zinc-700 dark:text-zinc-500 dark:hover:text-zinc-400 rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600">
                          <span class="sr-only">Close</span>
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                              fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clip-rule="evenodd"></path>
                          </svg>
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>


<header wire:id="PFLOWOhaQOKQFJy1R7k1"
  class="bg-white dark:bg-[#0f0f0f] shadow-sm w-full z-40 transition-all duration-200">
  <div x-data="window.TTRjRvxLbHzaKxW">

      <nav
          class="relative container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 justify-between items-center h-20 flex">


          <div class="inline-flex items-center min-w-fit">


              <a href="https://ugc4arabs.com" class="block ltr:sm:mr-6 rtl:sm:ml-6">
                  <img width="75px" height="25px"
                      src="assets/images/logo.png"
                      alt="ugc4arabs.com" style="height: 23px;width:auto">
              </a>

          </div>


          <!-- <form class="w-full hidden lg:block">
              <div class="relative max-w-md" x-data="{ open: false }">


                  <button type="button" wire:click="enter"
                      class="absolute top-0 ltr:right-0 rtl:left-0 p-2.5 text-white bg-zinc-700 hover:bg-zinc-800 ltr:rounded-r rtl:rounded-l border border-zinc-700 hover:border-zinc-800">
                      <svg class="w-5 h-5" stroke="currentColor" fill="currentColor" stroke-width="0"
                          viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z">
                          </path>
                      </svg>
                      <span class="sr-only">Search</span>
                  </button>



              </div>
          </form> -->


          <div
              class="min-w-fit ltr:sm:ml-10 rtl:sm:mr-10 flex items-center font-medium text-sm transition-all duration-200">



              <!-- Livewire Component wire-end:62BrgYY5ji5CEbo2iLez -->

              <div wire:id="wO1ESKCaIUU5fkrCimM6" x-data="window.XnbzELJbXoSEFED" x-init="init()">


                  <button x-on:click="window.location.href='/cart';" type="button"
                      class="text-gray-500 hover:text-primary-600 dark:text-gray-100 dark:hover:text-white transition-colors duration-300 py-2 relative md:mx-4">
                      <svg class="text-gray-400 hover:text-gray-700 h-6 w-6 dark:text-gray-100 dark:hover:text-white"
                          stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <g>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path
                                  d="M4 16V4H2V2h3a1 1 0 0 1 1 1v12h12.438l2-8H8V5h13.72a1 1 0 0 1 .97 1.243l-2.5 10a1 1 0 0 1-.97.757H5a1 1 0 0 1-1-1zm2 7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm12 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z">
                              </path>
                          </g>
                      </svg>
                      <span class="flex absolute h-2 w-2 top-0 ltr:right-0 rtl:left-0 mt-0 ltr:-mr-1 rtl:-ml-1">
                          <span
                              class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary-400 opacity-75"></span>
                          <span class="relative inline-flex rounded-full h-2 w-2 bg-primary-500"></span>
                      </span>
                  </button>


                  <div x-show="cart_open"
                      class="fixed inset-0 overflow-hidden z-50 preload" id="myDIV" x-ref="dialog"
                      aria-modal="true" style="display: none;">
                      <div class="absolute inset-0 overflow-hidden">


                          <div x-show="cart_open" x-transition:enter="ease-in-out duration-500"
                              x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
                              x-transition:leave="ease-in-out duration-500" x-transition:leave-start="opacity-100"
                              x-transition:leave-end="opacity-0"
                              class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                              aria-hidden="true" style="display: none;">
                          </div>


                          <div
                              class="pointer-events-none fixed inset-y-0 ltr:right-0 rtl:left-0 flex max-w-full ltr:pl-10 rtl:pr-10">
                              <div x-show="cart_open"
                                  x-transition:enter="transform transition ease-in-out duration-500 sm:duration-700"
                                  x-transition:enter-start="ltr:translate-x-full rtl:-translate-x-full"
                                  x-transition:enter-end="ltr:translate-x-0 rtl:-translate-x-0"
                                  x-transition:leave="transform transition ease-in-out duration-500 sm:duration-700"
                                  x-transition:leave-start="ltr:translate-x-0 rtl:-translate-x-0"
                                  x-transition:leave-end="ltr:translate-x-full rtl:-translate-x-full"
                                  class="pointer-events-auto w-screen max-w-xs lg:max-w-sm"
                                  style="display: none;">
                                  <div class="flex h-full flex-col bg-white dark:bg-zinc-700 shadow-xl">
                                      <div class="flex-1 overflow-y-auto overflow-x-hidden py-6 px-4 sm:px-6">
                                          <div class="flex items-start justify-between rtl:flex-row-reverse">
                                              <h2
                                                  class="text-base tracking-wide font-extrabold text-gray-900 dark:text-gray-200">
                                                  My shopping cart </h2>
                                              <div class="ml-3 flex h-7 items-center">
                                                  <button type="button"
                                                      class="-m-2 p-2 text-gray-400 hover:text-gray-500"
                                                      >
                                                      <span class="sr-only">Close panel</span>
                                                      <svg class="h-6 w-6"
                                                          x-description="Heroicon name: outline/x"
                                                          xmlns="http://www.w3.org/2000/svg" fill="none"
                                                          viewBox="0 0 24 24" stroke="currentColor"
                                                          aria-hidden="true">
                                                          <path stroke-linecap="round" stroke-linejoin="round"
                                                              stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                      </svg>
                                                  </button>
                                              </div>
                                          </div>

                                          <div class="mt-12">
                                              <div class="flow-root">
                                                  <ul role="list"
                                                      class="-my-6 divide-y divide-gray-200 dark:divide-zinc-600">

                                                      <li class="flex py-6" wire:key="shopping-cart-nav-item-0">
                                                          <div
                                                              class="h-16 w-16 flex-shrink-0 overflow-hidden rounded-md">
                                                              <img src="https://ugc4arabs.com/public/storage/gigs/previews/small/65A13BED6C60FD4733AB.mp3"
                                                                  alt="provide you with free-worry experiences"
                                                                  class="h-full w-full object-cover object-center">
                                                          </div>
                                                          <div class="ltr:ml-4 rtl:mr-4 flex flex-1 flex-col">
                                                              <div>
                                                                  <div
                                                                      class="flex justify-between text-sm font-medium text-gray-900 dark:text-white">
                                                                      <h3
                                                                          class="ltr:pr-2 rtl:pl-2 truncate max-w-[150px] lg:w-auto">
                                                                          <a href="https://ugc4arabs.com/service/provide-you-with-free-worry-experiences-B2353E3CFED961CAD758"
                                                                              target="_blank"
                                                                              class="hover:text-primary-600 font-bold truncate w-52 block">
                                                                              provide you with free-worry
                                                                              experiences </a>
                                                                      </h3>
                                                                      <p
                                                                          class="ltr:ml-5 rtl:mr-5  font-black text-gray-500 dark:text-gray-300 pt-0.5">
                                                                          $30.00</p>
                                                                  </div>
                                                              </div>
                                                              <div
                                                                  class="flex flex-1 items-end justify-between text-xs">
                                                                  <p class="text-gray-500 dark:text-gray-400">
                                                                      Quantity 1</p>
                                                                  <div class="flex">
                                                                      <button type="button"
                                                                          wire:click="remove('B2353E3CFED961CAD758')"
                                                                          wire:loading.attr="disabled"
                                                                          wire:target="remove('B2353E3CFED961CAD758')"
                                                                          class="font-medium text-red-600 hover:text-red-500">


                                                                          <div wire:loading=""
                                                                              wire:target="remove('B2353E3CFED961CAD758')">
                                                                              <svg role="status"
                                                                                  class="inline w-4 h-4 text-gray-700 animate-spin"
                                                                                  viewBox="0 0 100 101"
                                                                                  fill="none"
                                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                                  <path
                                                                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                      fill="#E5E7EB"></path>
                                                                                  <path
                                                                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                      fill="currentColor"></path>
                                                                              </svg>
                                                                          </div>


                                                                          <div wire:loading.remove=""
                                                                              wire:target="remove('B2353E3CFED961CAD758')">
                                                                              Supprimer
                                                                          </div>
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </li>

                                                  </ul>
                                              </div>
                                          </div>

                                      </div>
                                      <div
                                          class="border-t border-gray-200 dark:border-zinc-600 py-6 px-4 sm:px-6">
                                          <div
                                              class="flex justify-between text-base font-medium text-gray-900 dark:text-gray-200">
                                              <p>Sous-total</p>
                                              <p class="font-black">$30.00</p>
                                          </div>
                                          <p class="mt-0.5 text-sm text-gray-500 dark:text-gray-300">Tax fees
                                              calculated at checkout</p>
                                          <div class="mt-6">
                                              <a href="https://ugc4arabs.com/cart"
                                                  class="flex items-center justify-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-700">View
                                                  cart</a>
                                          </div>
                                          <div
                                              class="mt-6 flex justify-center text-center text-sm text-gray-500 dark:text-gray-300">
                                              <p>
                                                  Or <button type="button"
                                                      class="font-medium text-primary-600 hover:text-primary-600"
                                                      >Trouver d'autres voix<span
                                                          aria-hidden="true"> →</span></button>
                                              </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

              </div>


          </div>

      </nav>


      <div class="w-full h-px bg-gray-100 dark:bg-zinc-900 hidden md:block"></div>


      <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 hidden md:block">


          <ul
              class="relative flex items-center justify-between overflow-y-auto transition-colors duration-300 space-x-5 rtl:space-x-reverse scrollbar-thin scrollbar-thumb-gray-400 dark:scrollbar-thumb-zinc-600 scrollbar-track-gray-50 dark:scrollbar-track-zinc-400">
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/MoroccanArabic" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Moroccan Arabic (Darija – الدارجة)
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/french" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  French - France
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <button data-popover-target="popover-subcategories-BB914781DA60024855C1" data-popover-trigger="hover" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Arabic Classic – Fusha العربية الفصحى
              </button>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/EnglishUS" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                   English - US
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/amazigh" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Amazigh ( الأمازيغية – ⵜⴰⵎⴰⵣⵉⴳⵜ )
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/Spanish" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Spanish - Spain
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/arabic-saudi-arabia" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Arabic - Saudi Arabia
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/italian" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Italian 
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/lebanon-voiceover" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Lebanese - اللهجة اللبنانية
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/algerian-voice-over" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  Algerian - اللهجة الجزائرية
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/english-uk" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  English - UK
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/german-germany" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  German - Germany
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/french-africa" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  French - Africa
              </a>
          
      </li>
      
-->
              <!--
EDITED JUNE 4 


      <li class="inline-block flex-shrink-0 border-b-2 border-transparent hover:border-primary-600 focus:outline-none focus:ring-0 relative">

          
                                      <a href="https://ugc4arabs.com/categories/english-african" class="block text-[13px] font-medium text-gray-700 dark:text-gray-300 dark:group-hover:text-white group-hover:text-primary-600 py-2 focus:outline-none focus:ring-0">
                  English - African 
              </a>
          
      </li>
      
-->
          </ul>


          <div data-popover="" id="popover-subcategories-BB914781DA60024855C1" data-popover-placement="bottom"
              role="tooltip"
              class="absolute z-10 invisible inline-block w-64 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white rounded-md shadow-xl opacity-0 dark:text-zinc-400 dark:bg-zinc-800">
              <ul
                  class="max-h-96 !overflow-y-auto scrollbar-thin scrollbar-thumb-primary-600 scrollbar-track-white dark:scrollbar-track-zinc-800">

                  <li class="first:rounded-t-md">
                      <a href="https://ugc4arabs.com/categories/fusha/male"
                          class="flex items-center py-2 px-3 hover:bg-gray-50 dark:hover:bg-zinc-700"
                          style="border-radius: inherit">
                          <div class="flex-shrink-0 ltr:mr-2 rtl:ml-2">
                              <img class="w-7 h-7"
                                  src="https://ugc4arabs.com/public/storage/subcategories/E60C3F89625AFDA40405.png"
                                  data-src="https://ugc4arabs.com/public/storage/subcategories/E60C3F89625AFDA40405.png"
                                  alt="Male">
                          </div>
                          <div class="flex-1 min-w-0">
                              <p
                                  class="text-[13px] font-semibold text-gray-700 truncate dark:text-zinc-200 group-hover:text-primary-600">
                                  Male
                              </p>
                          </div>
                      </a>
                  </li>
                  <li class="first:rounded-t-md">
                      <a href="https://ugc4arabs.com/categories/fusha/female"
                          class="flex items-center py-2 px-3 hover:bg-gray-50 dark:hover:bg-zinc-700"
                          style="border-radius: inherit">
                          <div class="flex-shrink-0 ltr:mr-2 rtl:ml-2">
                              <img class="w-7 h-7"
                                  src="https://ugc4arabs.com/public/storage/subcategories/A7B233E564C46EFF7FA5.png"
                                  data-src="https://ugc4arabs.com/public/storage/subcategories/A7B233E564C46EFF7FA5.png"
                                  alt="Female">
                          </div>
                          <div class="flex-1 min-w-0">
                              <p
                                  class="text-[13px] font-semibold text-gray-700 truncate dark:text-zinc-200 group-hover:text-primary-600">
                                  Female
                              </p>
                          </div>
                      </a>
                  </li>

              </ul>
              <div class="bg-gray-50 dark:bg-zinc-700 rounded-b-md text-center">
                  <a href="https://ugc4arabs.com/categories/fusha"
                      class="block px-1 py-4 text-gray-500 dark:text-zinc-300 hover:text-primary-600 hover:underline text-xs tracking-wide font-semibold">
                      Browse Arabic Classic – Fusha العربية الفصحى
                  </a>
              </div>
          </div>

      </div>


      <div x-show="mobile_menu" style="display: none" class="fixed inset-0 flex z-40 lg:hidden"
          x-ref="mobile_menu">




          <div x-show="mobile_menu" style="display: none" x-transition:enter="ease-in-out duration-500"
              x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
              x-transition:leave="ease-in-out duration-500" x-transition:leave-start="opacity-100"
              x-transition:leave-end="opacity-0"
              class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true">
          </div>


          <div x-show="mobile_menu" style="display: none"
              x-transition:enter="transition ease-in-out duration-300 transform"
              x-transition:enter-start="ltr:-translate-x-full rtl:translate-x-full"
              x-transition:enter-end="ltr:translate-x-0 rtl:translate-x-0"
              x-transition:leave="transition ease-in-out duration-300 transform"
              x-transition:leave-start="ltr:translate-x-0 rtl:translate-x-0"
              x-transition:leave-end="ltr:-translate-x-full rtl:translate-x-full"
              class="relative max-w-[275px] w-full bg-white dark:bg-zinc-700 shadow-xl flex flex-col overflow-y-auto">




              <div class="w-full overflow-auto h-full pt-5">

                  <div class="flex items-center justify-center">




                      <button x-on:click="notifications_menu = true" type="button"
                          class="text-gray-500 hover:text-primary-600 transition-colors duration-300 py-2 relative mx-4 dark:text-gray-100 dark:hover:text-white md:hidden">
                          <svg class="text-gray-400 hover:text-gray-700 h-6 w-6 dark:text-gray-100 dark:hover:text-white"
                              stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z">
                              </path>
                          </svg>
                      </button>


                      <a href="https://ugc4arabs.com/inbox"
                          class="text-gray-500 hover:text-primary-600 transition-colors duration-300 py-2 relative mx-4 dark:text-gray-100 dark:hover:text-white md:hidden">
                          <svg class="text-gray-400 hover:text-gray-700 h-6 w-6 dark:text-gray-100 dark:hover:text-white"
                              stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z">
                              </path>
                          </svg>
                      </a>

                  </div>


                  <nav class="w-full border-b border-gray-100 dark:border-zinc-600 mb-[14px]">


                      <div class="w-full border-b border-gray-100 dark:border-zinc-600">
                          <div class="flex flex-col text-center divide-y divide-gray-200 dark:divide-zinc-600">
                              <div class="flex-1 flex flex-col p-8">
                                  <a href="https://ugc4arabs.com/profile/yussefbens">
                                      <img class="w-20 h-20 flex-shrink-0 mx-auto rounded-full object-cover lazy"
                                          src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                          data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                          alt="yussefbens">
                                  </a>
                                  <h3
                                      class="mt-6 text-gray-700 dark:text-white text-sm font-medium flex items-center justify-center">
                                      <span
                                          class="text-sm font-bold tracking-wider text-gray-700 dark:text-gray-100">yussefbens</span>
                                  </h3>
                                  <dl class="mt-1 flex-grow flex flex-col justify-between">
                                      <dd class="text-gray-900 text-sm font-black dark:text-white">$0.00</dd>
                                      <dd class="mt-3">
                                          <span
                                              class="px-4 py-1 text-blue-800 text-xs font-semibold bg-blue-100 rounded-full">
                                              Buyer
                                          </span>
                                      </dd>
                                  </dl>
                              </div>
                          </div>
                      </div>

                      <div class="border-b border-gray-100 dark:border-zinc-600">


                          <a href="https://ugc4arabs.com/account/settings"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                                  </path>
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Paramètres du compte </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/profile"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Modifier le profil </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/password"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Update password </span>

                          </a>

                      </div>

                      <div class="border-b border-gray-100 dark:border-zinc-600">


                          <a href="https://ugc4arabs.com/account/deposit"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                  stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Deposit </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/orders"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Mes commandes </span>

                          </a>


                          <a href="https://ugc4arabs.com/inbox"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> My messages </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/reviews"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> My reviews </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/refunds"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> My refunds </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/favorite"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Favorite list </span>

                          </a>

                      </div>

                      <div class="border-b border-gray-100 dark:border-zinc-600">


                          <a href="https://ugc4arabs.com/account/billing"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Billing information </span>

                          </a>


                          <a href="https://ugc4arabs.com/account/verification"
                              class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                              <svg xmlns="http://www.w3.org/2000/svg"
                                  class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                  <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z">
                                  </path>
                              </svg>


                              <span class="truncate text-sm font-semibold"> Centre de vérification </span>

                          </a>

                      </div>


                      <a href="https://ugc4arabs.com/auth/logout"
                          class="border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-zinc-600 hover:text-gray-900 group ltr:border-l-4 rtl:border-r-4 px-5 py-3 flex items-center text-sm font-medium">


                          <svg xmlns="http://www.w3.org/2000/svg"
                              class="text-gray-400 dark:text-gray-300 dark:group-hover:text-white group-hover:text-gray-500 flex-shrink-0 ltr:-ml-1 rtl:-mr-1 ltr:mr-3 rtl:ml-3 h-5 w-5"
                              fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1">
                              </path>
                          </svg>


                          <span class="truncate text-sm font-semibold"> Déconnexion </span>

                      </a>

                  </nav>




                  <a href="https://ugc4arabs.com/start_selling"
                      class="py-2 px-5 block text-gray-500 dark:text-gray-200 font-semibold text-sm">
                      Devenir un comédien voix off </a>


                  <a href="https://ugc4arabs.com/search"
                      class="py-2 px-5 block text-gray-500 dark:text-gray-200 font-semibold text-sm">
                      Trouver des voix </a>


                  <div x-data="{ open: false }" class="space-y-1">


                      <a href="javascript:void(0)"
                          class="py-2 px-5 text-gray-500 dark:text-gray-200 font-semibold text-sm flex items-center space-x-3 rtl:space-x-reverse relative z-1"
                          x-on:click="open = !open">
                          <span class="grow">
                              Browse categories </span>
                          <span x-bind:class="{ 'rotate-90': !open, 'rotate-0': open }"
                              class="transform transition ease-out duration-150 opacity-75 rotate-90">
                              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                  class="hi-solid hi-chevron-down inline-block w-4 h-4">
                                  <path fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                              </svg>
                          </span>
                      </a>


                      <div x-show="open" style="display: none"
                          x-transition:enter="transition ease-out duration-100"
                          x-transition:enter-start="transform -translate-y-6 opacity-0"
                          x-transition:enter-end="transform translate-y-0 opacity-100"
                          x-transition:leave="transition ease-in duration-100 bg-transparent"
                          x-transition:leave-start="transform translate-y-0 opacity-100"
                          x-transition:leave-end="transform -translate-y-6 opacity-0" class="relative z-0">

                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-22DA18C4EAC4DB3B8E9F">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/52346C2E21AEF9DD7403.png"
                                      alt="Moroccan Arabic (Darija – الدارجة)"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Moroccan Arabic (Darija
                                      – الدارجة) </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/MoroccanArabic"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Moroccan Arabic
                                          (Darija – الدارجة) </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-9A6E7762217C0F9D95CB">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/FC61CFCFC52E628AB796.png"
                                      alt="French - France" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> French - France </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/french"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse French - France
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-BB914781DA60024855C1">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/754A858659E61A78985E.png"
                                      alt="Arabic Classic – Fusha العربية الفصحى"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Arabic Classic – Fusha
                                      العربية الفصحى </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/fusha"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Arabic Classic –
                                          Fusha العربية الفصحى </span>
                                  </a>


                                  <a href="https://ugc4arabs.com/categories/fusha/male"
                                      class="flex items-center px-4 py-2 text-gray-500 dark:text-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Male </span>
                                  </a>
                                  <a href="https://ugc4arabs.com/categories/fusha/female"
                                      class="flex items-center px-4 py-2 text-gray-500 dark:text-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Female </span>
                                  </a>

                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-4FFFE616AEC3C6B2514C">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/0B4D8EFB6FAD98ABCFFA.png"
                                      alt=" English - US" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> English - US </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/EnglishUS"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse English - US
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-3829A1C8E7E04B9B55C6">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/7EE39787B336F024E7B7.png"
                                      alt="Amazigh ( الأمازيغية – ⵜⴰⵎⴰⵣⵉⴳⵜ )"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Amazigh ( الأمازيغية –
                                      ⵜⴰⵎⴰⵣⵉⴳⵜ ) </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/amazigh"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Amazigh (
                                          الأمازيغية – ⵜⴰⵎⴰⵣⵉⴳⵜ ) </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-61D945E558780A1F81A8">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/4C4D72D9F5DAC1F87054.png"
                                      alt="Spanish - Spain" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Spanish - Spain </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/Spanish"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Spanish - Spain
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-1C783E86F112B0C6ED32">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/EDDA2899BAB73AAE25B4.png"
                                      alt="Arabic - Saudi Arabia"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Arabic - Saudi Arabia
                                  </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/arabic-saudi-arabia"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Arabic - Saudi
                                          Arabia </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-DF0D199BE338B9F946C9">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      alt="Italian " class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Italian </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/italian"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Italian </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-67B277EED02377B6D8FC">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      alt="Lebanese - اللهجة اللبنانية"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Lebanese - اللهجة
                                      اللبنانية </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/lebanon-voiceover"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Lebanese -
                                          اللهجة اللبنانية </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-1CFDD1DE756CA1519B1F">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      alt="Algerian - اللهجة الجزائرية"
                                      class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> Algerian - اللهجة
                                      الجزائرية </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/algerian-voice-over"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse Algerian -
                                          اللهجة الجزائرية </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-9A55B3DE5A8357B140CE">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/C00D95970613C8507F21.png"
                                      alt="English - UK" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> English - UK </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/english-uk"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse English - UK
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-E1491DBF44BED7A43191">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/categories/4446AFA9E70E7BF3B7CB.png"
                                      alt="German - Germany" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> German - Germany
                                  </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/german-germany"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse German - Germany
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-505AE43CE28B7D701D71">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      alt="French - Africa" class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> French - Africa </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/french-africa"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse French - Africa
                                      </span>
                                  </a>



                              </nav>

                          </details>
                          <details class="group ltr:ml-4 rtl:mr-4"
                              wire:key="header-categories-DB1AA2494A94DFA74463">


                              <summary class="flex items-center text-gray-500 dark:text-gray-300 py-2 px-5">


                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      alt="English - African " class="lazy h-6 w-6 rounded-full object-contain">


                                  <span class="ltr:ml-3 rtl:mr-3 text-[13px] font-medium"> English - African
                                  </span>


                                  <span
                                      class="ltr:ml-auto rtl:mr-auto transition duration-300 shrink-0 group-open:-rotate-180">
                                      <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 20 20"
                                          fill="currentColor">
                                          <path fill-rule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clip-rule="evenodd"></path>
                                      </svg>
                                  </span>

                              </summary>


                              <nav class="mt-1.5 ltr:ml-8 rtl:mr-8 flex flex-col">


                                  <a href="https://ugc4arabs.com/categories/english-african"
                                      class="flex items-center px-4 py-2 text-gray-800 dark:text-gray-100 rounded-lg hover:bg-gray-100 dark:hover:bg-zinc-700 hover:text-gray-700 dark:hover:text-gray-200">
                                      <span class="ltr:ml-3 rtl:mr-3 text-xs font-medium"> Browse English -
                                          African </span>
                                  </a>



                              </nav>

                          </details>

                      </div>
                  </div>


                  <div class="w-full h-px bg-gray-100 dark:bg-zinc-600 my-3"></div>


                  <a href="https://ugc4arabs.com"
                      class="py-2 px-5 flex items-center text-gray-500 dark:text-gray-200 font-semibold text-sm">
                      <svg class="w-[18px] h-[18px] ltr:mr-2.5 rtl:ml-2.5" stroke="currentColor"
                          fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
                          </path>
                      </svg>
                      l'accueil </a>


                  <div x-data="{ open: false }" class="space-y-1">


                      <a href="javascript:void(0)"
                          class="py-2 px-5 text-gray-500 dark:text-gray-200 font-semibold text-sm flex items-center space-x-3 rtl:space-x-reverse relative z-1"
                          x-on:click="open = !open">
                          <span class="grow flex items-center">
                              <svg class="w-[18px] h-[18px] ltr:mr-2.5 rtl:ml-2.5" stroke="currentColor"
                                  fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm7.931 9h-2.764a14.67 14.67 0 0 0-1.792-6.243A8.013 8.013 0 0 1 19.931 11zM12.53 4.027c1.035 1.364 2.427 3.78 2.627 6.973H9.03c.139-2.596.994-5.028 2.451-6.974.172-.01.344-.026.519-.026.179 0 .354.016.53.027zm-3.842.7C7.704 6.618 7.136 8.762 7.03 11H4.069a8.013 8.013 0 0 1 4.619-6.273zM4.069 13h2.974c.136 2.379.665 4.478 1.556 6.23A8.01 8.01 0 0 1 4.069 13zm7.381 6.973C10.049 18.275 9.222 15.896 9.041 13h6.113c-.208 2.773-1.117 5.196-2.603 6.972-.182.012-.364.028-.551.028-.186 0-.367-.016-.55-.027zm4.011-.772c.955-1.794 1.538-3.901 1.691-6.201h2.778a8.005 8.005 0 0 1-4.469 6.201z">
                                  </path>
                              </svg>
                              French
                          </span>
                          <span x-bind:class="{ 'rotate-90': !open, 'rotate-0': open }"
                              class="transform transition ease-out duration-150 opacity-75 rotate-90">
                              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
                                  class="hi-solid hi-chevron-down inline-block w-4 h-4">
                                  <path fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"></path>
                              </svg>
                          </span>
                      </a>


                      <div x-show="open" style="display: none"
                          x-transition:enter="transition ease-out duration-100"
                          x-transition:enter-start="transform -translate-y-6 opacity-0"
                          x-transition:enter-end="transform translate-y-0 opacity-100"
                          x-transition:leave="transition ease-in duration-100 bg-transparent"
                          x-transition:leave-start="transform translate-y-0 opacity-100"
                          x-transition:leave-end="transform -translate-y-6 opacity-0" class="relative z-0">

                          <div wire:click="setLocale('en')"
                              class="py-2 px-5 rounded-sm inline-flex items-center cursor-pointer justify-between hover:bg-gray-50 dark:hover:bg-zinc-600 focus:outline-none w-full">
                              <div class="inline-flex items-center">
                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/vendor/blade-flags/country-us.svg"
                                      alt="English" class="lazy w-5 ltr:mr-3 rtl:ml-3">
                                  <span class="font-normal text-xs dark:text-gray-300">English</span>
                              </div>
                              <div wire:loading="" wire:target="setLocale('en')">
                                  <svg role="status" class="block w-4 h-4 text-gray-700 animate-spin"
                                      viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                          fill="#E5E7EB"></path>
                                      <path
                                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                          fill="currentColor"></path>
                                  </svg>
                              </div>
                          </div>
                          <div
                              class="py-2 px-5 rounded-sm inline-flex items-center cursor-pointer justify-between bg-primary-100/25 text-primary-600 focus:outline-none w-full">
                              <div class="inline-flex items-center">
                                  <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                                      data-src="https://ugc4arabs.com/public/vendor/blade-flags/country-fr.svg"
                                      alt="French" class="lazy w-5 ltr:mr-3 rtl:ml-3">
                                  <span class="font-normal text-xs dark:text-gray-300">French</span>
                              </div>
                              <div class="block">
                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none"
                                      viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7">
                                      </path>
                                  </svg>
                              </div>
                          </div>

                      </div>

                  </div>


                  <a href="https://ugc4arabs.com/help/contact"
                      class="py-2 px-5 flex items-center text-gray-500 dark:text-gray-200 font-semibold text-sm">
                      <svg class="w-[18px] h-[18px] ltr:mr-2.5 rtl:ml-2.5" stroke="currentColor"
                          fill="currentColor" stroke-width="0" viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M12 6a3.939 3.939 0 0 0-3.934 3.934h2C10.066 8.867 10.934 8 12 8s1.934.867 1.934 1.934c0 .598-.481 1.032-1.216 1.626a9.208 9.208 0 0 0-.691.599c-.998.997-1.027 2.056-1.027 2.174V15h2l-.001-.633c.001-.016.033-.386.441-.793.15-.15.339-.3.535-.458.779-.631 1.958-1.584 1.958-3.182A3.937 3.937 0 0 0 12 6zm-1 10h2v2h-2z">
                          </path>
                          <path
                              d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z">
                          </path>
                      </svg>
                      Demander un devis </a>


              </div>

          </div>

      </div>


      <div x-show="notifications_menu" style="display: none" class="fixed inset-0 flex z-40"
          x-ref="notifications_menu">


          <div x-show="notifications_menu" style="display: none" x-transition:enter="ease-in-out duration-500"
              x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
              x-transition:leave="ease-in-out duration-500" x-transition:leave-start="opacity-100"
              x-transition:leave-end="opacity-0"
              class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
               aria-hidden="true">
          </div>


          <div x-show="notifications_menu" style="display: none"
              x-transition:enter="transition ease-in-out duration-300 transform"
              x-transition:enter-start="ltr:translate-x-full rtl:-translate-x-full"
              x-transition:enter-end="ltr:translate-x-0 rtl:-translate-x-0"
              x-transition:leave="transition ease-in-out duration-300 transform"
              x-transition:leave-start="ltr:-translate-x-0 rtl:translate-x-0"
              x-transition:leave-end="ltr:translate-x-full rtl:-translate-x-full"
              class="fixed ltr:right-0 rtl:left-0 max-w-sm w-full bg-white dark:bg-zinc-700 shadow-xl flex flex-col h-full">


              <div x-show="notifications_menu" x-transition:enter="ease-in-out duration-300"
                  x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
                  x-transition:leave="ease-in-out duration-300" x-transition:leave-start="opacity-100"
                  x-transition:leave-end="opacity-0" class="top-0 ltr:right-0 rtl:left-0 pt-2 block sm:hidden"
                  style="display: none;">
                  <button type="button"
                      class="ltr:ml-1 rtl:mr-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      >
                      <span class="sr-only">Close sidebar</span>
                      <svg class="h-6 w-6 text-white" x-description="Heroicon name: outline/x"
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M6 18L18 6M6 6l12 12"></path>
                      </svg>
                  </button>
              </div>


              <div class="pt-8 px-6 lg:px-6 w-full sm:flex sm:justify-between sm:items-center">
                  <div class="flex justify-center sm:justify-left">
                      <h3 class="inline-flex items-center font-semibold">
                          <span class="text-base dark:text-gray-100">Notifications</span>
                      </h3>
                  </div>
              </div>


              <div
                  class="w-full overflow-auto h-full scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 dark:scrollbar-thumb-zinc-900 dark:scrollbar-track-zinc-600">
                  <div class="space-y-2 py-6">
                  </div>
              </div>

          </div>

      </div>

  </div>
</header>


<!-- Livewire Component wire-end:PFLOWOhaQOKQFJy1R7k1 -->





<div class="home-hero-section" style="position: relative; display: flex;align-items: center;justify-content: center; overflow: hidden;">
  <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-full flex items-center">
      <div class="w-full md:max-w-lg py-12">


          <h1
              class="mt-4 text-2xl tracking-tight font-extrabold text-white text-center sm:mt-5 sm:text-4xl lg:mt-6 xl:text-5xl">
              #1 Marketplace UGC for Arabs<br>
          </h1>

          <p class="mt-4 text-white text-center sm:text-lg lg:text-xl">
              Embrace the Leading User-Generated Content Marketplace Curated for Arabs. Engage, Connect, and Showcase Your Voice in a Community Built Just for You. Join Us Today!
          </p>

          <div class="mt-6 sm:mt-6">



              <div>

                  <!-- <select id="language"
                      class="bg-white border-none text-gray-900 text-sm font-medium rounded-md px-2.5 py-4 focus:outline-none focus:ring-0"
                      style="padding: 15px 20px 15px 20px;font-size: 16px;margin-left:0px;margin-right:0px;margin-top:50px!important;margin-buttom:10px;">
                      <option value="vido">Language</option>
                      <option value="arabic" selected="">Moroccan Arabic الدارجة</option>
                      <option value="frensh">French - France</option>
                      <option value="fusha">Arabic – Fusha العربية الفصحى</option>
                      <option value="english">English - Anglais</option>&gt;
                      <option value="amazigh">Amazigh ( الأمازيغية – ⵜⴰⵎⴰⵣⵉⴳⵜ )</option>
                      <option value="spain">Spanish - Spain</option>

                  </select>
                  <select style="display:none;" id="gender"
                      class="bg-white border-none text-gray-900 text-sm font-medium rounded-md px-2.5 py-4 focus:outline-none focus:ring-0">
                      <option value="vide">Gender</option>
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                  </select> -->

                  <button type="button"

                      class="Farroor text-sm font-medium text-white bg-gray-800 rounded-md border-none hover:bg-gray-900 focus:ring-0 focus:outline-none"
                      id="submit"
                      style="display: block; margin: 0 auto; padding: 15px 65px 15px 65px;font-size: 16px;margin-top:10px!important;margin-buttom:10px;"
                      (click)="scroll(target)">
                      Get Started
                  </button>


              </div>
              <p class="text-sm font-medium" style="color: red; padding-left:35px;" id="filter_error"> </p>
              <br>


              <!--   <div class="hidden sm:flex items-center text-white font-semibold text-sm whitespace-nowrap">
                  Popular: 
                  <ul class="flex ltr:ml-3 rtl:mr-3">
                                                              <li class="flex ltr:mr-3 rtl:ml-3 whitespace-nowrap">
                              <a href="https://ugc4arabs.com/categories/amazigh" class="border-2 border-white rounded-[40px] hover:bg-white hover:text-gray-700 transition-all duration-200 px-3 py-0.5 text-xs">
                                  Amazigh ( الأمازيغية – ⵜⴰⵎⴰⵣⵉⴳⵜ )
                              </a>
                          </li>
                                                              <li class="flex ltr:mr-3 rtl:ml-3 whitespace-nowrap">
                              <a href="https://ugc4arabs.com/categories/arabic-saudi-arabia" class="border-2 border-white rounded-[40px] hover:bg-white hover:text-gray-700 transition-all duration-200 px-3 py-0.5 text-xs">
                                  Arabic - Saudi Arabia
                              </a>
                          </li>
                                                              <li class="flex ltr:mr-3 rtl:ml-3 whitespace-nowrap">
                              <a href="https://ugc4arabs.com/categories/italian" class="border-2 border-white rounded-[40px] hover:bg-white hover:text-gray-700 transition-all duration-200 px-3 py-0.5 text-xs">
                                  Italian 
                              </a>
                          </li>
                                                              <li class="flex ltr:mr-3 rtl:ml-3 whitespace-nowrap">
                              <a href="https://ugc4arabs.com/categories/lebanon-voiceover" class="border-2 border-white rounded-[40px] hover:bg-white hover:text-gray-700 transition-all duration-200 px-3 py-0.5 text-xs">
                                  Lebanese - اللهجة اللبنانية
                              </a>
                          </li>
                                                              <li class="flex ltr:mr-3 rtl:ml-3 whitespace-nowrap">
                              <a href="https://ugc4arabs.com/categories/french-africa" class="border-2 border-white rounded-[40px] hover:bg-white hover:text-gray-700 transition-all duration-200 px-3 py-0.5 text-xs">
                                  French - Africa
                              </a>
                          </li>
                                                      </ul>
              </div> -->

          </div>

      </div>
      <div class="video-container">
          <video id="background-video" onloadedmetadata="this.muted=true" autoplay loop muted>
              <source src="https://billo.app/wp-content/uploads/2022/08/creators-bg.mp4" type="video/mp4">
          </video>
      </div>
  </div>
</div>

<div class="flex flex-wrap gap-2 justify-center" style="margin: 20px;">
  <div class="flex flex-nowrap overflow-x-auto gap-2" style="margin-top: 10px;">
    <div class="flex justify-center" style="margin: 20px;">
        <button type="button" class="btn" onclick="location.href='https://wa.me/212623841732?text=Pouvez%20vous%20maidez'">
            <i class="fab fa-whatsapp"></i> Let us help you!
        </button>
    </div>
  </div>
</div>



<main class="flex-grow">
  <div class="container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 pt-16 pb-24 space-y-8 min-h-screen">
      <div wire:id="2kyOgIZDdEWpswLT25Yo" class="w-full">
          <div class="grid grid-cols-12 gap-6">

              <!-- Google Tag Manager (noscript) -->
              <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5CJ52V8W" height="0"
                      width="0" style="display:none;visibility:hidden"></iframe></noscript>
              <!-- End Google Tag Manager (noscript) -->






              <div class="col-span-12 mb-16">


                  <div class="block mb-6" #target>
                      <div class="flex justify-between items-center bg-transparent py-2">

                          <div>
                              <span class="font-extrabold text-xl text-gray-800 dark:text-gray-100 pb-1 block">
                                  UGC selected for you
                              </span>
                          </div>

                          <!-- <div>
                              <a href="https://ugc4arabs.com/search"
                                  class=" text-sm font-semibold text-primary-600 hover:text-primary-700 sm:block">
                                  Show more


                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 hidden ltr:inline"
                                      fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M13 7l5 5m0 0l-5 5m5-5H6"></path>
                                  </svg>


                                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 hidden rtl:inline"
                                      fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                      <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M11 17l-5-5m0 0l5-5m-5 5h12"></path>
                                  </svg>
                              </a>
                          </div> -->

                      </div>
                  </div>




                  <div class="grid grid-cols-12 sm:gap-x-9 gap-y-6">
                      <div class="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-3 xl:col-span-3"
                      *ngFor="let creator of creators">
                          <div wire:id="XR4T0SuYTHzppTrGhoH8" class="gig-card"
                              x-data="window._282FC44AD52693D0FF9B" dir="ltr" x-init="initialize()">
                              <div
                                  class="bg-white dark:bg-zinc-800 rounded-md shadow-sm ring-1 ring-gray-200 dark:ring-zinc-800">

                                  <video controls>
                                      <source [src]="creator.video" type="video/mp4">
                                  </video>



                                  <div class="px-4 pb-2 mt-4">


                                      <div class="w-full flex justify-between items-center">
                                          <a href="#"
                                              class="flex-shrink-0 group block">
                                              <div class="flex items-center">
                                                  <span class="inline-block relative">
                                                      <!-- Increased the size of the image to h-12 w-12 -->
                                                      <img class="h-12 w-12 rounded-full object-cover"
                                                          [src]="creator.image">
                                                  </span>
                                                  <div class="ltr:ml-3 rtl:mr-3">
                                                      <div
                                                          class="text-gray-500 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-300 flex items-center mb-0.5 font-extrabold tracking-wide text-[13px]">
                                                          {{creator.num}}
                                                      </div>
                                                  </div>
                                              </div>
                                          </a>
                                      </div>


                                      <br>
                                      <div class="gig-card-price">
                                          <small class="text-body-3 dark:!text-zinc-200">price:</small><span
                                              class=" dark:!text-white">${{creator.price}}</span>
                                      </div>

                                  </div>


                                  <div
                                      class="px-3 py-3 bg-[#fdfdfd] dark:bg-zinc-800 border-t border-gray-50 dark:border-zinc-700 text-right sm:px-4 rounded-b-md flex justify-between">

                                      <a [href]="'https://wa.me/212623841732?text=I%20want%20to%20order%20a%20ugc%20from%20this%20freelancer%3A%20'+creator.num"
                                          style="width: 100%;"
                                          target="_blank">
                                          <button type="button" id="custom-button"
                                              class="w-full border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-[13px] font-medium text-primary-600 focus:outline-none" 
                                              style="padding: 8px 20px; font-size: 13px;">Contact</button>
                                      </a>
                                      <!--
<a href="https://ugc4arabs.com/inbox/7F839D84615138761F1F" target="_blank">
<button type="button" id="custom-button" class="w-full border border-transparent rounded-md py-4 px-8 flex items-center justify-center text-[13px] font-medium text-primary-600 focus:outline-none" style="padding: 8px 20px; font-size: 13px;">Contact</button>
</a> -->




                                  </div>

                              </div>

                          </div>





                          <!-- Livewire Component wire-end:XR4T0SuYTHzppTrGhoH8 -->
                      </div>
                      
                  </div>
              </div>

              <div class="col-span-12 mb-16">

                  <div>
                      <span class="font-extrabold text-xl text-gray-800 dark:text-gray-100 pb-1 block">
                          Why ugc4arabs.com?
                      </span>
                  </div>
                  <div class="col-span-12 mb-16" style="font-family: 'Poppins', sans-serif;">
                      <div class="responsive-section"
                          style="display: flex; flex-wrap: wrap; justify-content: center; gap: 20px; padding: 20px;">

                          <!-- Quick Delivery -->
                          <div class="responsive-box"
                              style="flex-basis: calc(25% - 20px); padding: 15px; background: #F2F7FF; border-radius: 8px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); transition: transform 0.3s ease-in-out; display: flex; align-items: center;">
                              <i class="fas fa-shipping-fast"
                                  style="font-size: 24px; color: #051670; margin-right: 10px;"></i>
                              <div>
                                  <h2>Quick Delivery</h2>
                                  <p>Delivery in less than 24 hours.</p>
                              </div>
                          </div>

                          <!-- Satisfaction Guaranteed -->
                          <div class="responsive-box"
                              style="flex-basis: calc(25% - 20px); padding: 15px; background: #F2F7FF; border-radius: 8px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); transition: transform 0.3s ease-in-out; display: flex; align-items: center;">
                              <i class="fas fa-thumbs-up"
                                  style="font-size: 24px; color: #051670; margin-right: 10px;"></i>
                              <div>
                                  <h2>Satisfaction</h2>
                                  <p>Satisfied or refunded.</p>
                              </div>
                          </div>

                          <!-- Free Demos -->
                          <div class="responsive-box"
                              style="flex-basis: calc(25% - 20px); padding: 15px; background: #F2F7FF; border-radius: 8px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); transition: transform 0.3s ease-in-out; display: flex; align-items: center;">
                              <i class="fas fa-play-circle"
                                  style="font-size: 24px; color: #051670; margin-right: 10px;"></i>
                              <div>
                                  <h2>Free Demos</h2>
                                  <p>Sample our talents.</p>
                              </div>
                          </div>

                          <!-- Affordable Prices -->
                          <div class="responsive-box"
                              style="flex-basis: calc(25% - 20px); padding: 15px; background: #F2F7FF; border-radius: 8px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05); transition: transform 0.3s ease-in-out; display: flex; align-items: center;">
                              <i class="fas fa-tag"
                                  style="font-size: 24px; color: #051670; margin-right: 10px;"></i>
                              <div>
                                  <h2>Affordable</h2>
                                  <p>Great value for every budget.</p>
                              </div>
                          </div>

                      </div>
                  </div>


                  <!-- Hover Animation -->

                  <!-- Google Font Link -->
                  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&amp;display=swap"
                      rel="stylesheet">

                  <div class="col-span-12 mb-16">



                  </div>






              </div>
          </div>



          <!-- Livewire Component wire-end:2kyOgIZDdEWpswLT25Yo -->
      </div>
  </div>
</main>





<footer
  class="bg-white dark:bg-zinc-800 px-4 sm:px-6 lg:px-60 pt-20 pb-10 lg:pb-20 relative z-10">
  <div class="container mx-auto px-4">



      <div
          class="grid md:flex justify-center md:justify-between items-center border-t-2 border-gray-50 dark:border-zinc-700 pt-4">


          <div class="flex items-center justify-center md:justify-items-start mb-4 md:mb-0">


              <div>
                  <a href="https://ugc4arabs.com" class="h-full">
                      <img src="assets/images/logo.png"
                          data-src="https://ugc4arabs.com/public/storage/site/footer/logo/1127ADE2E027C299CE08.png"
                          alt="ugc4arabs.com" class="py-2.5 max-h-20 w-auto" height="50" width="150">
                  </a>
              </div>

          </div>


          <div>
              <div class="flex items-center space-x-2">















              </div>
          </div>

      </div>


      <div class="text-center mt-10">


          <div class="text-gray-400 dark:text-gray-200 font-normal text-sm mb-2">

          </div>


          <div class="flex space-x-4 justify-center rtl:space-x-reverse">


              <a href="https://ugc4arabs.com/page/terms-conditions"
                  class="text-xs font-medium uppercase text-gray-600 dark:text-gray-400 dark:hover:text-gray-100 hover:underline">Privacy
                  policy</a>


              <a href="https://ugc4arabs.com/page/terms-conditions"
                  class="text-xs font-medium uppercase text-gray-600 dark:text-gray-400 dark:hover:text-gray-100 hover:underline">Terms
                  of service</a>


              <a href="https://ugc4arabs.com/blog"
                  class="text-xs font-medium uppercase text-gray-600 dark:text-gray-400 dark:hover:text-gray-100 hover:underline">Blog</a>

          </div>

      </div>

  </div>


  <div x-show="languages" style="display: none;"
      class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-languages" aria-modal="true">
      <div class="flex md:items-end justify-center md:min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

          <div x-show="languages" style="display: none;" x-transition:enter="ease-out duration-300"
              x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
              x-transition:leave="ease-in duration-200" x-transition:leave-start="opacity-100"
              x-transition:leave-end="opacity-0"
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
              aria-hidden="true"></div>

          <!-- <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">&ZeroWidthSpace;</span> -->

          <div x-show="languages" style="display: none;" x-transition:enter="ease-out duration-300"
              x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
              x-transition:leave="ease-in duration-200"
              x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
              x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              class="relative inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg">

              <div class="grid grid-cols-2 gap-2 sm:p-6">

                  <div wire:click="setLocale('en')"
                      class="py-2 px-4 rounded-sm inline-flex items-center cursor-pointer justify-between hover:bg-gray-50 focus:outline-none">
                      <div class="inline-flex items-center">
                          <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                              data-src="https://ugc4arabs.com/public/img/flags/rounded/us.svg" alt="English"
                              class="lazy w-5 ltr:mr-3 rtl:ml-3">
                          <span class="font-normal text-xs">English</span>
                      </div>
                      <div wire:loading="" wire:target="setLocale('en')">
                          <svg role="status" class="block w-4 h-4 text-gray-700 animate-spin"
                              viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                  fill="#E5E7EB"></path>
                              <path
                                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                  fill="currentColor"></path>
                          </svg>
                      </div>
                  </div>
                  <div
                      class="py-2 px-4 rounded-sm inline-flex items-center cursor-pointer justify-between bg-blue-50 text-blue-600 focus:outline-none">
                      <div class="inline-flex items-center">
                          <img src="https://ugc4arabs.com/public/storage/default/default-placeholder.jpg"
                              data-src="https://ugc4arabs.com/public/img/flags/rounded/fr.svg" alt="French"
                              class="lazy w-5 ltr:mr-3 rtl:ml-3">
                          <span class="font-normal text-xs">French</span>
                      </div>
                      <div class="block">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                              stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7"></path>
                          </svg>
                      </div>
                  </div>

              </div>


              <div class="bg-gray-50 px-4 py-3 flex justify-center items-center">
                  <button type="button"
                      class="w-full inline-flex justify-center px-4 py-2 bg-transparent text-xs font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 sm:w-auto">
                      Close
                  </button>
              </div>


          </div>


      </div>
  </div>
</footer>