import { Component } from '@angular/core';
import { ApiService } from './shared/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'web';

  creators: Array<any>;

  constructor(private apiService: ApiService) {

  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: "smooth"});
  }

  ngOnInit() {
    this.apiService.getCreators().subscribe((res: any) => {
      this.creators = res.creators;
      console.log('creators', this.creators)
    })
  }
}
